<!-- eslint-disable vue/singleline-html-element-content-newline -->
<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <div>
    <b-card
      no-body
      class="coupon-card"
    >
      <b-card-header>
        <div
          class="d-flex flex-wrap justify-content-between align-items-center w-100 mb-2"
          style="gap: 8px;"
        >
          <h4 class="mb-0">
            {{ $t('Basic information') }}
          </h4>
        </div>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            md="12"
          >
            <b-form-group
              :label="$t('Discount name')"
            >
              <b-form-input />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              :label="$t('Cookie storage time')"
            >
              <b-form-input />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              :label="$t('Minimum withdrawal amount')"
            >
              <b-form-input />
            </b-form-group>
          </b-col>
          <b-col
            md="12"
          >
            <b-form-group
              :label="$t('Type of discount')"
            >
              <b-form-radio-group
                v-model="discountTypeSelected"
                :options="discountTypes"
                name="radio-discountTypes"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="12"
          >
            <b-row>
              <b-col
                md="8"
              >
                <!-- discountTypeSelected === 1: % -->
                <b-form-group
                  v-if="discountTypeSelected === 1"
                >
                  <b-input-group
                    append="%"
                  >
                    <b-form-input />
                  </b-input-group>
                </b-form-group>

                <!-- discountTypeSelected === 2: Categories -->
                <b-form-group
                  v-else-if="discountTypeSelected === 2"
                >
                  <b-form
                    class="repeater-form mb-2"
                    @submit.prevent="repeatCategory('productCategorySelected')"
                  >
                    <div
                      v-for="(item, index) in productCategorySelected"
                      :id="String(index)"
                      :key="index"
                      class="d-flex align-items-center mb-1 position-relative"
                    >
                      <treeselect
                        v-model="productCategorySelected[index]"
                        :multiple="false"
                        :clearable="true"
                        :searchable="true"
                        :open-on-click="true"
                        :clear-on-select="true"
                        :show-count="true"
                        :options="productCategories"
                        :limit="5"
                        :max-height="400"
                        @select="onTreeselectSelect"
                      />
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-danger"
                        class="btn-icon rounded-circle ml-2"
                        @click="removeCategory('productCategorySelected',index)"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </div>
                    <b-form-group>
                      <div class="fade-browse" @click="repeatCategory('productCategorySelected')">
                        <feather-icon icon="PlusCircleIcon" class="mr-25" />
                        {{ $t('Add options') }}
                      </div>
                    </b-form-group>
                  </b-form>

                  <!-- Advance -->
                  <div class="advance-block mb-2">
                    <b-row>
                      <b-col lg="3" class="d-flex flex-column justify-content-center">
                        {{ $t('Batch setting') }}
                        <div><strong>{{ productCategoriesSelectedCount }}</strong>&nbsp;{{ $t('selected products') }}</div>
                      </b-col>
                      <b-col style="max-width: 140px;">
                        <b-form-group
                          :label="$t('Discount amount')"
                        >
                          <b-input-group
                            append="%"
                            class="mx-auto"
                          >
                            <b-form-input v-model="applyOn.percent" type="number" min="1" max="100" class="text-right" />
                          </b-input-group>
                        </b-form-group>
                      </b-col>

                      <b-col
                        class="d-flex align-items-center justify-content-end ml-auto"
                        style="gap: 8px; max-width: 300px;"
                      >
                        <b-button
                          variant="gradient-success"
                          size="sm"
                          pill
                          class="w-100"
                          @click="advanceApply"
                        >
                          <feather-icon
                            icon="CheckIcon"
                            class="mr-50"
                          />
                          <span class="align-middle">{{ $t('Apply') }}</span>
                        </b-button>

                        <b-button
                          variant="gradient-danger"
                          size="sm"
                          pill
                          class="w-100"
                          :disabled="productCategoriesSelectedCount == 0"
                          @click="advanceDelete"
                        >
                          <feather-icon
                            icon="Trash2Icon"
                            class="mr-50"
                          />
                          <span class="align-middle">{{ $t('Delete') }}</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>

                  <!-- table -->
                  <vue-good-table
                    ref="selected-product-categories"
                    class="custom-good-table custom-good-table-ctrl-border"
                    style-class="vgt-table"
                    :columns="fields2"
                    :rows="productCategorySelectedList"
                    :search-options="{
                      enabled: true,
                      externalQuery: searchTerm }"
                    :pagination-options="{
                      enabled: true,
                      perPage:pageLength
                    }"
                  >
                    <!-- Table: Head -->
                    <template
                      slot="table-column"
                      slot-scope="props"
                    >
                      <span class="text-capitalize">
                        {{ $t(props.column.label) }}
                      </span>
                    </template>

                    <!-- Table: Rows -->
                    <template
                      slot="table-row"
                      slot-scope="props"
                    >
                      <!-- Column: Discount Percent -->
                      <span
                        v-if="props.column.field === 'discount_percent'"
                        class="d-inline-flex text-center"
                      >
                        <b-input-group
                          append="%"
                          class="mx-auto"
                        >
                          <b-form-input type="number" min="1" max="100" class="text-right" />
                        </b-input-group>
                      </span>

                      <!-- Column: Actions -->
                      <span
                        v-else-if="props.column.field === 'actions'"
                        class="d-inline-flex text-center"
                      >
                        <b-button
                          variant="flat-danger"
                          class="text-danger btn-icon rounded-circle"
                          @click="handlerRemove(props.row.id)"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </span>

                      <!-- Column: Common -->
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>

                    <!-- pagination -->
                    <template
                      slot="pagination-bottom"
                      slot-scope="props"
                    >
                      <div class="d-flex justify-content-between flex-wrap p-2">
                        <div class="d-flex align-items-center mb-0 mt-1">
                          <span class="text-nowrap ">
                            Showing 1 to
                          </span>
                          <b-form-select
                            v-model="pageLength"
                            :options="['3','5','10']"
                            class="mx-1"
                            @input="(value)=>props.perPageChanged({currentPerPage:value})"
                          />
                          <span class="text-nowrap"> of {{ props.total }} entries </span>
                        </div>
                        <div>
                          <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="(value)=>props.pageChanged({currentPage:value})"
                          >
                            <template #prev-text>
                              <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                              />
                            </template>
                            <template #next-text>
                              <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                              />
                            </template>
                          </b-pagination>
                        </div>
                      </div>
                    </template>
                  </vue-good-table>
                </b-form-group>

                <!-- discountTypeSelected === 3: Products -->
                <b-form-group
                  v-else-if="discountTypeSelected === 3"
                >
                  <div
                    v-b-modal.modal-product-list
                    class="fade-browse mb-2"
                  >
                    <feather-icon icon="PlusCircleIcon" class="mr-25" />
                    {{ $t('Add product') }}
                  </div>

                  <!-- Advance -->
                  <div class="advance-block mb-2">
                    <b-row>
                      <b-col lg="3" class="d-flex flex-column justify-content-center">
                        {{ $t('Batch setting') }}
                        <div><strong>{{ productCategoriesSelectedCount }}</strong>&nbsp;{{ $t('selected products') }}</div>
                      </b-col>
                      <b-col style="max-width: 140px;">
                        <b-form-group
                          :label="$t('Discount amount')"
                        >
                          <b-input-group
                            append="%"
                            class="mx-auto"
                          >
                            <b-form-input v-model="applyOn.percent" type="number" min="1" max="100" class="text-right" />
                          </b-input-group>
                        </b-form-group>
                      </b-col>

                      <b-col
                        class="d-flex align-items-center justify-content-end ml-auto"
                        style="gap: 8px; max-width: 300px;"
                      >
                        <b-button
                          variant="gradient-success"
                          size="sm"
                          pill
                          class="w-100"
                          @click="advanceApply"
                        >
                          <feather-icon
                            icon="CheckIcon"
                            class="mr-50"
                          />
                          <span class="align-middle">{{ $t('Apply') }}</span>
                        </b-button>

                        <b-button
                          variant="gradient-danger"
                          size="sm"
                          pill
                          class="w-100"
                          :disabled="productCategoriesSelectedCount == 0"
                          @click="advanceDelete"
                        >
                          <feather-icon
                            icon="Trash2Icon"
                            class="mr-50"
                          />
                          <span class="align-middle">{{ $t('Delete') }}</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>

                  <!-- table -->
                  <vue-good-table
                    ref="promotion-products"
                    class="custom-good-table custom-good-table-ctrl-border"
                    style-class="vgt-table"
                    :columns="fields"
                    :rows="selectedProducts"
                    :search-options="{
                      enabled: true,
                      externalQuery: searchTerm }"
                    :pagination-options="{
                      enabled: true,
                      perPage:pageLength
                    }"
                  >
                    <!-- Table: Head -->
                    <template
                      slot="table-column"
                      slot-scope="props"
                    >
                      <span class="text-capitalize">
                        {{ $t(props.column.label) }}
                      </span>
                    </template>

                    <!-- Table: Rows -->
                    <template
                      slot="table-row"
                      slot-scope="props"
                    >
                      <!-- Column: Name -->
                      <span
                        v-if="props.column.field === 'title'"
                        class="d-flex align-items-center"
                      >
                        <b-avatar
                          :src="props.row.avatar"
                          size="lg"
                          class="mr-1"
                          square
                        />
                        <span class="d-block text-nowrap" style="width: 240px;">{{ props.row.name }}</span>
                      </span>

                      <!-- Column: Actions -->
                      <span
                        v-else-if="props.column.field === 'actions'"
                        class="d-inline-flex text-center"
                      >
                        <b-button
                          variant="flat-danger"
                          class="text-danger btn-icon rounded-circle"
                          @click="handlerRemove(props.row.id)"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </span>

                      <!-- Column: Common -->
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>

                    <!-- pagination -->
                    <template
                      slot="pagination-bottom"
                      slot-scope="props"
                    >
                      <div class="d-flex justify-content-between flex-wrap p-2">
                        <div class="d-flex align-items-center mb-0 mt-1">
                          <span class="text-nowrap ">
                            Showing 1 to
                          </span>
                          <b-form-select
                            v-model="pageLength"
                            :options="['3','5','10']"
                            class="mx-1"
                            @input="(value)=>props.perPageChanged({currentPerPage:value})"
                          />
                          <span class="text-nowrap"> of {{ props.total }} entries </span>
                        </div>
                        <div>
                          <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="(value)=>props.pageChanged({currentPage:value})"
                          >
                            <template #prev-text>
                              <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                              />
                            </template>
                            <template #next-text>
                              <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                              />
                            </template>
                          </b-pagination>
                        </div>
                      </div>
                    </template>
                  </vue-good-table>
                </b-form-group>
              </b-col>
              <b-col
                md="4"
              >
                <b-form-group
                  :label="$t('Status')"
                >
                  <b-form-checkbox
                    checked="true"
                    class="custom-control-success"
                    switch
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            md="12"
          >
            <b-button
              variant="primary"
            >
              {{ $t('Save') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- modal add selected -->
    <b-modal
      id="modal-product-list"
      size="lg"
      scrollable
      title="Tìm kiếm sản phẩm"
      no-stacking
      centered
    >
      <div class="mih-100">
        <b-input-group>
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input :placeholder="$t('Product name')" />
          <b-input-group-append is-text>
            <span class="cursor-pointer">
              {{ $t('Search') }}
            </span>
          </b-input-group-append>
        </b-input-group>
        <div class="mt-1">
          <b-row class="bg-gray">
            <b-col cols="9">{{ $t('Products') }}</b-col>
            <b-col cols="3" class="text-right text-capitalize">{{ $t('inventory') }}</b-col>
          </b-row>
          <div>
            <div>
              <div
                v-for="(item, index) in items" :key="index"
                class="mt-1"
              >
                <div>
                  <label :for="`parent-${item.id}`" class="d-flex align-items-center hover-bg-gray cursor-pointer">
                    <b-form-checkbox :id="`parent-${item.id}`" v-model="item.selected" :indeterminate="item.indeterminate" @change="handleSelectAll(item)" />
                    <div class="d-flex align-items-center pl-1">
                      <b-avatar square variant="light-dark" :src="item.avatar" />
                      <span class="pl-1">{{ item.name }}</span>
                    </div>
                  </label>
                </div>
                <div>
                  <div
                    v-for="(val, idx) in item.attribute" :key="idx"
                    class="mt-1"
                  >
                    <label :for="`${val.id}`" class="d-flex justify-content-between hover-bg-gray cursor-pointer">
                      <div class="d-flex pl-4">
                        <b-form-checkbox :id="`${val.id}`" v-model="val.selected" @change="handleSelectItem(val)" />
                        <div class="pl-1">
                          <span class="d-block">{{ val.name }}</span>
                          <span class="d-block"><strong>SKU:&nbsp;</strong>{{ val.sku }}</span>
                          <span class="d-block"><strong>Barcode:&nbsp;</strong>{{ val.barcode }}</span>
                        </div>
                      </div>
                      <div class="pr-1">{{ val.stock }} {{ $t('inventory') }}</div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer="{ok, cancel}">
        <div class="w-100">
          <p
            class="float-left mb-0 "
            :class="selectedArr.length ? 'text-primary cursor-pointer' : ''"
            @click="modalShowItem = !modalShowItem"
          >
            {{ selectedArr.length }} {{ $t('selected products') }}
          </p>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right"
            @click="ok"
          >
            {{ $t('Apply') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="float-right mr-2"
            @click="cancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  // BCardTitle,
  BCardBody,
  VBTooltip,
  BForm,
  BFormInput,
  BFormGroup,
  BFormRadioGroup,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormCheckbox,
  BModal,
  BPagination,
  BAvatar,
  BFormSelect,
  // BBadge,
  // BDropdown,
  // BDropdownItem,
  // BFormCheckbox,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Treeselect from '@riophae/vue-treeselect'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'

export default {
  name: 'DiscountList',
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    // BCardTitle,
    BCardBody,
    // flatPickr,
    BForm,
    BFormInput,
    BFormGroup,
    BFormRadioGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormCheckbox,
    BModal,
    BPagination,
    BAvatar,
    BFormSelect,
    // BBadge,
    VueGoodTable,
    Treeselect,
    // vSelect,
    // BDropdown,
    // BDropdownItem,
    // LanguageSelect,
    // BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      discountTypes: [
        {
          value: 1,
          text: 'Theo giá trị đơn hàng',
        },
        {
          value: 2,
          text: 'Danh mục sản phẩm',
        },
        {
          value: 3,
          text: 'Sản phẩm',
        },
      ],
      discountTypeSelected: 1,
      items: [
        {
          id: 1,
          name: 'Áo thun bao lỗ cực mát',
          avatar: 'https://beta-fileapi.busodevelopers.com//platform/101/2021/11/platform_products/desktop/7ec3869e91252f21fd8f24c379f209f4.png',
          selected: false,
          indeterminate: false,
          attribute: [
            {
              parent_id: 1,
              id: 11,
              name: 'Xanh/ S/ Thun',
              stock: 5,
              sku: 'A0038687',
              barcode: 'A0038687',
              selected: false,
            },
            {
              parent_id: 1,
              id: 12,
              name: 'Đen/ S/ Thun',
              stock: 5,
              sku: 'A00386877',
              barcode: 'A00386877',
              selected: false,
            },
          ],
        },
        {
          id: 2,
          name: 'Áo Khoác cực mát',
          avatar: 'https://beta-fileapi.busodevelopers.com//platform/101/2021/11/platform_products/desktop/7ec3869e91252f21fd8f24c379f209f4.png',
          selected: false,
          indeterminate: false,
          attribute: [
            {
              parent_id: 2,
              id: 21,
              name: 'Đen/ XL/ Kaki',
              stock: 1,
              sku: 'A00386878',
              barcode: 'A00386878',
              selected: false,
            },
          ],
        },
      ],
      pageLength: 10,
      searchTerm: '',
      selectedArr: [],
      fields: [
        { field: 'title', label: 'Products', sortable: true },
        { field: 'price', label: 'Price', sortable: false },
        {
          field: 'stock', label: 'inventory', sortable: false, tdClass: 'text-center',
        },
        {
          field: 'discount_percent', label: '% discount', sortable: false, tdClass: 'text-center',
        },
        {
          field: 'actions', label: 'Actions', sortable: false, tdClass: 'text-center',
        },
      ],
      fields2: [
        { field: 'label', label: 'Category', sortable: true },
        {
          field: 'discount_percent', label: '% discount', sortable: false, tdClass: 'text-center',
        },
        {
          field: 'actions', label: 'Actions', sortable: false, tdClass: 'text-center',
        },
      ],
      selectedProducts: [
        {
          id: 1,
          name: 'Áo thun bao lỗ cực mát',
          avatar: 'https://beta-fileapi.busodevelopers.com//platform/101/2021/11/platform_products/desktop/7ec3869e91252f21fd8f24c379f209f4.png',
          selected: false,
          indeterminate: false,
          price: 150000,
          stock: 95,
          discount_percent: 0,
        },
        {
          id: 2,
          name: 'Nước mắm Nam Ngư',
          avatar: 'https://beta-fileapi.busodevelopers.com//upload/286/2022/1/products_media/desktop/7007da574fb0b6cf6a8f522f161eec82.jpeg',
          selected: false,
          indeterminate: false,
          price: 175000,
          stock: 65,
          discount_percent: 0,
        },
      ],
      productCategories: [],
      productCategorySelected: [],
      // productCategorySelectedList: [],
      productCategoriesSelectedCount: 0,
      applyOn: {
        percent: 0,
      },
    }
  },
  computed: {
    productCategorySelectedList() {
      const result = []
      if (this.productCategorySelected.length === 0) return result
      // eslint-disable-next-line array-callback-return
      this.productCategorySelected.map(item => {
        if (item) {
          result.push(this.productCategories.find(cate => cate.id === item))
        }
      })
      // eslint-disable-next-line consistent-return
      return result
    },
  },
  mounted() {
    this.loadCategoryList()
  },
  methods: {
    handleSelectItem(val) {
      // eslint-disable-next-line
      // find index of attribute in arr
      const parent = this.items.findIndex(x => x.id === val.parent_id)
      let d = 0
      // eslint-disable-next-line array-callback-return
      this.items[parent].attribute.map(x => {
        if (x.selected) {
          // eslint-disable-next-line no-plusplus
          d++
        }
      })
      if (d > 0) this.items[parent].indeterminate = true
      if (d === 0) {
        this.items[parent].selected = false
        this.items[parent].indeterminate = false
      }
      if (d === this.items[parent].attribute.length) {
        this.items[parent].selected = true
        this.items[parent].indeterminate = false
      }
    },
    handleSelectAll(val) {
      const index = this.items.find(x => x.id === val.id)
      // eslint-disable-next-line array-callback-return
      index.attribute.map(x => {
        // eslint-disable-next-line no-param-reassign
        x.selected = val.selected
      })
      this.saveTempArr()
    },
    saveTempArr(item, option = {}) {
      // eslint-disable-next-line array-callback-return
      if (option.all) {
        this.selectedArr.map(x => x.id.include(item.id))
      }
    },
    handlerRemove(id) {
      this.selectedProducts.splice(this.selectedProducts.findIndex(item => item.id === id), 1)
    },
    async loadCategoryList() {
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/tree?site_id=${siteId}`,
        )
        if (result.status === 200) {
          if (result.data.status) {
            this.productCategories = []
            const res = result.data.data
            // eslint-disable-next-line array-callback-return
            res.map(x => {
              const item = {
                id: x.id_category,
                label: x.name,
                slug: x.slug,
                idx: x.id_category,
                children: [],
              }
              if (x.children && x.children.length > 0) {
                // eslint-disable-next-line array-callback-return
                x.children.map(y => {
                  const xitem = {
                    id: y.id_category,
                    label: y.name,
                    slug: y.slug,
                    idx: y.id_category,
                    children: [],
                    parent_id: x.id_category,
                  }
                  if (y.children && y.children.length > 0) {
                    // eslint-disable-next-line array-callback-return
                    y.children.map(z => {
                      const zitem = {
                        id: z.id_category,
                        label: z.name,
                        slug: z.slug,
                        idx: z.id_category,
                        parent_id: y.id_category,
                        root_id: x.id_category,
                      }
                      xitem.children.push(zitem)
                    })
                  }
                  item.children.push(xitem)
                })
              }
              this.productCategories.push(item)
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(result.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    repeatCategory(elm) {
      this[elm].push(null)
    },
    removeCategory(elm, index) {
      this[elm].splice(index, 1)
    },
    // onTreeselectSelect(data) {
    // },
    // advanceApply() {
    // },
    // advanceDelete() {
    // },
    async loadSelectedCategoryList() {
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/tree?site_id=${siteId}`,
        )
        if (result.status === 200) {
          if (result.data.status) {
            this.productCategorySelectedList = []
            const res = result.data.data
            // eslint-disable-next-line array-callback-return
            res.map(x => {
              const item = {
                id: x.id_category,
                label: x.name,
                slug: x.slug,
                idx: x.id_category,
                children: [],
              }
              if (x.children && x.children.length > 0) {
                // eslint-disable-next-line array-callback-return
                x.children.map(y => {
                  const xitem = {
                    id: y.id_category,
                    label: y.name,
                    slug: y.slug,
                    idx: y.id_category,
                    children: [],
                    parent_id: x.id_category,
                  }
                  if (y.children && y.children.length > 0) {
                    // eslint-disable-next-line array-callback-return
                    y.children.map(z => {
                      const zitem = {
                        id: z.id_category,
                        label: z.name,
                        slug: z.slug,
                        idx: z.id_category,
                        parent_id: y.id_category,
                        root_id: x.id_category,
                      }
                      xitem.children.push(zitem)
                    })
                  }
                  item.children.push(xitem)
                })
              }
              this.productCategorySelectedList.push(item)
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(result.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>

<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css"></style>
<style lang="scss">
.custom-good-table .vgt-table {
  font-size: 14px;

  thead th {
    vertical-align: middle;
    text-align: center;
    padding-right: 0.75em;
    white-space: nowrap;
  }
  td {
    vertical-align: middle;
  }
}
.custom-good-table-ctrl-border{
  .vgt-table{
    border: 1px solid #fff !important;
  }
  td{
    padding: 0.75rem !important;

    &:first-child {
      padding-left: 1.5rem !important;
    }
    &:last-child {
      padding-right: 1.5rem !important;
    }
  }
  thead{
    background-color: #f3f2f7
  }
}
.v-select-custom {
  .vs {
    &__selected-options {
      max-width: calc(100% - 24px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-wrap: nowrap;
    }
    &__search {
      &::placeholder {
        color: var(--gray)
      }
    }
  }
}
.fade-browse {
  border: 1px dashed var(--primary);
  color: var(--primary);
  border-radius: 4px;
  padding: 8px 12px;
  text-align: center;
  position: relative;
  cursor: pointer;

  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
